<template>
    <v-container>
      <v-card flat color="brown" max-width="700" class="mx-auto my-12 py-5 text-center" dark>
        <p class="titulo-success">Your License expired</p>
        <!-- <v-icon x-large color="white">
              mdi-check-outline
        </v-icon> -->
      </v-card>
        
        <br>
      <div class="text-center">

     
        <v-btn color="blue-grey" class="mx-auto white--text" @click="logout">
          Log Out
          <v-icon
            right
            dark
          >
            mdi-logout-variant
          </v-icon>
        </v-btn>
         </div>
        <!-- <a href="/login">Go App</a> -->
    </v-container>
</template>
<script>

import { auth } from '../../../plugins/firebase'
export default {
  data() {
    return {
    }
  },
  created () {

  },
  methods: {
    async logout () {
      await auth().signOut()
      localStorage.removeItem('email');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      location.href = "/login";
    },
  },
  mounted() {

  }
}
</script>
<style>
  .titulo-success{
    font-size: 35px;
    font-weight: 500;

    margin: 5px;
  }
</style>